.my-custom-react-select__menu {
    z-index: 2;
  }

  .hthZGQ {
    height: 100% !important;
  }
  
  .dVJiJx {
    height: 100% !important;
  }